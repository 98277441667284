<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
// import PageHeader from '@components/page-header'
import axios from '@/axios'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    //  PageHeader
  },
  data() {
    return {
      title: 'Course label',
      name: '',
      nameState: null,
      submittedNames: '',
      coursetags: '',
    }
  },
  mounted() {
    this.getCourseTags()
  },

  methods: {
    async getCourseTags() {
      const data = await axios
        .get('/api/coursetag')
        .then((res) => res.data)
        .catch((err) => {
          this.$toast.error(err.response.data.error.message)
        })

      data.map((x) => {
        x.value = x._id
        x.text = x.tag_name
      })

      this.optionsTag = [{ value: '', text: '' }, ...data]
      this.coursetags = data
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      // await axios.post('/api/')
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      let data = {
        tag_name: this.name,
      }
      await axios
        .post('/api/coursetag', data)
        .then((result) => {
          // this.$router.push('/contents')
        })
        .catch((err) => {
          alert(err.response.data.error.message)
        })

      // Push the name to submitted names

      // Hide the modal manually
      this.$nextTick(() => {
        this.getCourseTags()
        this.name = ''
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    async handleClickDeleteContenttag(id) {
      var confirmDel = confirm('Are you sure you want to delete?')
      if (confirmDel) {
        await axios
          .delete(`/api/coursetag/${id}`)
          .then((result) => {
            this.getCourseTags()
          })
          .catch((err) => {
            // console.log(err)
            this.$toast.error(err.response.data.error.message)
          })
      }
    },
    async handleClickEditContent(content) {
      let tag_name = prompt('Please enter your Label name', content.tag_name)
      if (!tag_name) return
      await axios
        .patch(`/api/coursetag/${content._id}`, { tag_name: tag_name })
        .then((result) => {
          this.getCourseTags()
        })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Course label</h4>
      </div>
      <div class="col-md-3 col-xl-6">
        <button
          v-b-modal.modal-prevent-closing
          class="btn btn-primary rounded-pill width-lg float-right aligncenter"
        >
          <feather type="file-plus"></feather>Create
        </button>

        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          title="Create Label "
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
        >
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
              :state="nameState"
              label="Tag Name"
              label-for="name-input"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name-input"
                v-model="name"
                :state="nameState"
                required
              ></b-form-input>
            </b-form-group>
          </form>
        </b-modal>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" style="width: 70%">Label Name</th>

                    <th scope="col" class="thcenter">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in coursetags" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.tag_name }}</td>

                    <td class="action">
                      <button
                        class="btn btn-warning style-button"
                        @click="handleClickEditContent(item)"
                      >
                        <feather type="edit"></feather>
                      </button>
                      <button
                        class="btn btn-danger style-button"
                        @click="handleClickDeleteContenttag(item._id)"
                      >
                        <feather type="delete"></feather>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
